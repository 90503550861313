<template>
	<div class="d-flex justify-content-center h-100 align-items-center">
		<div class="">
			<div class="text-center">
        <img src="@/assets/images/error.png" alt="" height="300">
      </div>
			<h1 class="text-center">Error</h1>
			<h2>Unable to connect to server</h2>
			<div class="text-center">
				<router-link to="/"
					><button class="btn btn-lg btn-primary">
						Retry
					</button></router-link
				>
			</div>
		</div>
	</div>
</template>
